import { Step, StepDescription, StepsSection } from "../reusable.styles"

import CtaSection from "../components/sections/CtaSection"
import GeneralHeader from "../components/global/GeneralHeader"
import ContactForm from "../components/global/ContactForm"
import SectionWrapper from "../components/sections/SectionWrapper"
import MarketoForm from "../components/global/MarketoForm"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import MarketoFormMediator from "../components/global/MarketoFormMediator"
import Button from "../components/global/Button"
import styled from "styled-components"
import { queries } from "../breakpoints"

const taxExempt = ({ location }) => {
  const showThankYouScreen = () => {
    // Show the thank you message
    let thankYou = document.getElementById("form-thank-you")
    thankYou.style.display = "block"

    // Hide the form
    let form = document.getElementById("contact-form")
    form.style.display = "none"
  }

  const valuePropsGrubhub = [
    {
      headline: "Free Grubhub+ for all employees",
      subheadline:
        "Your team can enjoy $0 Grubhub delivery fees on all eligible orders*, 5% credit back on pickup, lower service fees, and exclusive offers. Active Grubhub+ members through individual Amazon Prime memberships save an average of $336 a year!",
      imageSrc:
        "https://res.cloudinary.com/grubhub-marketing/image/upload/v1725614513/Corporate/grubhubplus.png",
    },
    {
      headline: "Free Corporate Account",
      subheadline:
        "Order up deliciousness for the whole team with the industry’s most flexible meal perks platform—with 0% corporate fees*, which saves an average of $40,000 annually.**",
      imageSrc:
        "https://res.cloudinary.com/grubhub-marketing/image/upload/v1725614467/Corporate/grubhubCorporateAccounts.png",
    },
  ]

  const valuePropsPrime = [
    {
      headline: "Feed any occasion",
      subheadline:
        "From virtual meetings to in-office events, boost employee engagement and delight clients with fast and reliable food delivery.",
      imageSrc:
        "https://res.cloudinary.com/grubhub-marketing/image/upload/v1725524488/Corporate/bike.png",
    },
    {
      headline: "Cater to every taste with group ordering",
      subheadline:
        "Grubhub has the most group ordering options compared to any other platform, which means your employees can order the food they want and everyone’s meal shows up at the same time.",
      imageSrc:
        "https://res.cloudinary.com/grubhub-marketing/image/upload/v1725524633/Corporate/food.png",
    },
    {
      headline: "Offer benefits that go beyond business hours",
      subheadline:
        "With Grubhub+, your employees can enjoy unlimited $0 delivery fees on eligible orders during or after work.***",
      imageSrc:
        "https://res.cloudinary.com/grubhub-marketing/image/upload/v1725524596/Corporate/bag.png",
    },
  ]

  return (
    <Layout
      location={location}
      title={"Business Prime | Grubhub Corporate Account"}
      description={
        "Grubhub and Business Prime have partnered to give Business Prime members two new exciting benefits: free Grubhub+ memberships and free corporate accounts for group food ordering"
      }
      noindex={true}
      nofollow={true}
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "Corporate Business Prime",
        pageName: "amazon-business-prime",
        brand: "grubhub",
      }}
    >
      <StyledNavbarOnlyLogo>
        <NavbarContainerOnlyLogo>
          <LogoHeader
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1725523048/Corporate/business-prime.png"
            alt="Grubhub Amazon Prime"
            placeholder="blurred"
            width={196}
          />
        </NavbarContainerOnlyLogo>
      </StyledNavbarOnlyLogo>
      <StyledSectionWrapper backgroundColor="#0678FF">
        <div className="row justify-content-between align-items-start">
          <StyledHeader className="col-lg-6 col-12 text-center text-lg-left hero-left">
            <Headline>
              Grubhub+ is now a Business Prime benefit for your whole team
            </Headline>
            <Subheadline>
              <b>
                Grubhub and Amazon Business Prime have partnered to give your
                team the benefits of Grubhub+ with no expiration and no Grubhub+
                membership fees. Now, your whole crew can enjoy $0 Grubhub
                delivery fees on eligible orders* and more benefits, as long as
                your Business Prime account is active.
              </b>
            </Subheadline>
            <Subheadline>
              <b>
                Plus, your company can unlock 0% corporate fees with a free
                Corporate Account to make feeding your team easier than ever.
              </b>
            </Subheadline>
            <Image
              src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1725571382/Corporate/packages.png"
              alt="grubhub amazon packages"
            />
            <Subheadline className="text-small">
              See below for terms
            </Subheadline>
          </StyledHeader>
          <StyledFormContainer id="contact-form" className="col-lg-5 col-12">
            <FormHeading>Get in touch</FormHeading>
            <MarketoForm
              id={[2116]}
              labelBellow={false}
              onSubmit={() => showThankYouScreen()}
            />
          </StyledFormContainer>
          <StyledFormContainer
            id="form-thank-you"
            className="col-lg-5 col-12"
            style={{ display: "none" }}
          >
            <Image
              src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1725653163/Corporate/hand.png"
              alt="green thumbs up"
            />
            <FormHeading>Thanks for reaching out!</FormHeading>
            <MarketoFormMediator
              id={[2119]}
              replicate={true}
              labelBellow={false}
              confirmMessage="Thanks for reaching out!!"
              eventCategory="corporate business page interaction"
              eventAction="subscribe_cta"
            />
          </StyledFormContainer>
        </div>
      </StyledSectionWrapper>
      <SectionDualQuote>
        <ValuePropsSection
          id="two-columns"
          headline="Now, you get two tasty ways to treat your team for free"
          valueProps={valuePropsGrubhub}
          layout="vertical"
          backgroundColor="#F5F3F1"
        />
      </SectionDualQuote>
      <TextContainer>
        <StandardText>
          *Valid on eligible orders, additional fees may apply. See full terms
          here:{" "}
          <a target="_blank" href="https://lp.grubhub.com/abp-gh-plus-terms">
            https://lp.grubhub.com/abp-gh-plus-terms
          </a>
        </StandardText>
        <StandardText>**Based on an internal 2024 report.</StandardText>
      </TextContainer>
      <SectionTripleQuote>
        <ValuePropsSection
          headline="Business Prime Members now get a free Corporate Account to"
          valueProps={valuePropsPrime}
          layout="vertical"
          backgroundColor="#FFFFFF"
        />
      </SectionTripleQuote>
      <StyledQuoteSection>
        <div className="container">
          <QuoteSubHeading>
            <b>Join the thousands of companies who love Grubhub</b>
          </QuoteSubHeading>
          <QuoteHeading>
            “Grubhub has been such a welcomed perk. Not only have our current
            employees loved this benefit, it has been a great surprise for new
            hires to receive on their first day!”
          </QuoteHeading>
          <QuoteSubHeading className="quoteSubHeading">
            - Taylor H, HR Partner, PakEnergy
          </QuoteSubHeading>
          <Image
            src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1725533525/Corporate/logoQuote.png"
            alt="Pak Energy"
            className="quoteImage"
          />
        </div>
      </StyledQuoteSection>
      <StyledCtaSection>
        <Image
          src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1725538580/Corporate/whiteLogoGHandPrime.png"
          alt="white logo Grubhub and Prime"
        />
        <CtaHeadline>Upgrade your team benefits today</CtaHeadline>
        <Button to="#" type="primary">
          Get started
        </Button>
      </StyledCtaSection>
    </Layout>
  )
}

export default taxExempt

const StyledQuoteSection = styled.section`
  font-family: var(--jetSans-fontFamily);
  padding: 80px 0;
  background-color: #f5f3f1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .quoteSubHeading {
    color: #6b6b83;
  }
  .quoteImage {
    margin-top: 30px;
    max-width: 160px;
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const QuoteHeading = styled.h2`
  font-family: var(--jetSans-fontFamily);
  margin: 30px 0;
  font-weight: 800;
  font-size: clamp(var(--fontSize-5), -0.875rem + 8.333vw, var(--fontSize-7));
  @media (${queries.max_break_md}) {
    padding: 0 15px;
  }
`

const QuoteSubHeading = styled.h3`
  font-family: var(--jetSans-fontFamily);
  font-size: clamp(var(--fontSize-1), 1.15vw, 3rem);
`

const StyledCtaSection = styled.section`
  font-family: var(--jetSans-fontFamily);
  padding: 80px 0;
  background-color: #0678ff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 320px;
    width: 100%;
    height: auto;
    display: block;
  }
  a {
    font-family: var(--jetSans-fontFamily);
    text-decoration: none;
    background: #fff;
    color: #262632;
    margin-top: 20px;
    transition: 0.3s linear all;
    &:hover {
      background-color: #f8f9fa;
      color: #262632;
    }
  }
`

const CtaHeadline = styled.h2`
  font-family: var(--jetSans-fontFamily);
  color: #fff;
  font-weight: 800;
  font-size: clamp(var(--fontSize-5), -0.875rem + 8.333vw, var(--fontSize-7));
  @media (${queries.max_break_md}) {
    padding: 0 15px;
  }
`

const TextContainer = styled.div`
  font-family: var(--jetSans-fontFamily);
  padding: 0 28% 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: #f5f3f1;
  @media (${queries.max_break_md}) {
    padding: 0 10% 60px;
  }
`

const StandardText = styled.p`
  font-family: var(--jetSans-fontFamily);
  text-align: center;
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 20px;
  color: #212529;
`

const StyledNavbarOnlyLogo = styled.header`
  -webkit-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(67, 41, 163, 0.1);
  padding: 20px 0;
  z-index: 2000;
  position: relative;
`

const NavbarContainerOnlyLogo = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
`

const LogoHeader = styled.img`
  min-width: 280px;
  align-self: center;
`

const SectionTripleQuote = styled.section`
  * {
    font-family: var(--jetSans-fontFamily);
  }
`

const SectionDualQuote = styled.section`
  * {
    font-family: var(--jetSans-fontFamily);
  }
  .value-props-item {
    flex: 0 0 50%;
    max-width: 50%;
    @media (${queries.max_break_md}) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`

const StyledSectionWrapper = styled(SectionWrapper)`
  .hero-left {
    @media (${queries.max_break_md}) {
      padding: 40px 0 30px;
    }
  }
  h1 {
    font-family: var(--jetSans-fontFamily);
    color: #fff;
    font-weight: 800;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    font-family: var(--jetSans-fontFamily);
    font-size: 16px;
    color: #fff;
    line-height: 1.45;
    text-align: center;
  }
  .text-small {
    font-size: 14px;
  }
  @media (${queries.max_break_md}) {
    padding: 0;
    padding-bottom: 0;
    .container {
      max-width: unset;
    }
  }
`

const StyledHeader = styled.div`
  @media (${queries.max_break_md}) {
    align-content: center;
    padding: 0;
  }
`

const Headline = styled.h1`
  font-family: var(--jetSans-fontFamily);
  font-weight: 800;
  font-size: clamp(var(--fontSize-5), -0.875rem + 8.333vw, var(--fontSize-7));
  @media (${queries.max_break_md}) {
    padding: 0 15px;
  }
`

const Subheadline = styled.p`
  font-size: var(--fontSize-2);
  @media (${queries.max_break_md}) {
    padding: 0 15px;
  }
`

const Image = styled.img`
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`

const FormHeading = styled.h2`
  font-family: var(--jetSans-fontFamily);
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 20px;
  @media (${queries.max_break_md}) {
    text-align: center;
  }
`

const StyledFormContainer = styled.div`
  &#form-thank-you {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 0px 20px 10px rgba(0, 0, 0, 0.15);
    padding: 40px;
    width: 100% !important;
    @media (${queries.max_break_md}) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 40px 20px;
    }
    p {
      color: var(--color-heading);
    }
    h2 {
      font-family: var(--jetSans-fontFamily);
      font-weight: 800;
      text-align: center;
    }
    img {
      max-width: 50px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    div[class*="confirmform"] {
      display: none !important;
    }
    .marketo-mediator {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      & * {
        font-family: var(--jetSans-fontFamily);
      }
      .mktoField[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin: auto;
        border: 1px solid #000;
        border-radius: 3px;
      }
    }
    .mktoButton {
      background-color: #f36805;
      width: 100%;
      margin-top: 8px;
    }
    .mktoRadioList {
      input {
        margin-right: 3px;
      }
      label:first-of-type {
        margin-right: 10px;
      }
    }
  }
  &#contact-form {
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 0px 20px 10px rgba(0, 0, 0, 0.15);
    padding: 40px;
    width: 100% !important;
    @media (${queries.max_break_md}) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 40px 20px;
    }
    & * {
      font-family: var(--jetSans-fontFamily);
      font-weight: 800;
    }
    .mktoLabel {
      margin-top: 5px;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .mktoField {
      font-family: var(--jetSans-fontFamily);
      position: relative;
      border: 1px solid rgba(67, 41, 163, 0.19);
      font-weight: var(--fontWeight-light);
      border-radius: 4px;
      width: 100%;
      padding: 10px;
      margin-bottom: 5px;
      @media (${queries.min_break_md}) {
        padding: 11px;
      }
    }
    &&& .mktoError {
      width: 100%;
      color: red;
      font-weight: var(--fontWeight-light);
      font-size: var(--fontSize-0);
      text-align: left;
      margin-bottom: 5px;
    }
    &&& .mktoButton {
      width: 100%;
      margin-top: 30px;
    }
    // Validation styles
    .mktoInvalid {
      border-color: #c3363e;
    }
    .mktoErrorMsg {
      font-family: var(--fontFamily-body);
      color: #c3363e;
      font-size: 12px;
    }
    .mktoValid {
      border-color: #13aa37;
    }
    .mktoButton {
      background-color: #f36805;
      width: 100%;
    }
  }
`
